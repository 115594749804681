<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>My Earnings</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div style="width: 110px" v-bind="attrs" v-on="on">
            <span>
              <FiscalYearField
                :numberOfFutureYears="0"
                :numberOfPastYears="2"
                @inputObj="
                  selectedFiscalYear = $event;
                  onSubmit();
                "
                :client="selectedClient"
                flat
                solo-inverted
                hide-details
                class="mr-2"
                use-store
              ></FiscalYearField>
            </span></div></template
        >Fiscal Year</v-tooltip
      >
      <v-btn @click="onSubmit" color="primary" class="d-none d-sm-flex">Search</v-btn>

      <template v-slot:extension>
        <v-tabs v-model="tab">
          <v-tab>
            {{ $i18n.translate("Earned") }}
          </v-tab>
          <v-tab>
            {{ $i18n.translate("Pending") }}
          </v-tab>
          <v-tab>
            {{ $i18n.translate("Expired") }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <ParticipantEarnedWidget dark :keyword="search" :to="{ query: { tab: 0 } }"></ParticipantEarnedWidget>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <ParticipantPendingWidget dark :keyword="search" :to="{ query: { tab: 1 } }"></ParticipantPendingWidget>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <ParticipantExpiredWidget dark :keyword="search" :to="{ query: { tab: 2 } }"></ParticipantExpiredWidget>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <MyEarningsTable
                  :filters="filters"
                  :expired="false"
                  :pending="false"
                  label="Showing Earned Awards"
                  description="These awards have been earned and paid out"
                />
              </v-tab-item>
              <v-tab-item>
                <MyEarningsTable
                  :filters="filters"
                  :expired="false"
                  :pending="true"
                  label="Showing Pending Awards"
                  description="These awards have are pending payment for either course completion or approval"
                />
              </v-tab-item>
              <v-tab-item>
                <MyEarningsTable
                  :filters="filters"
                  :expired="true"
                  label="Showing Expired Awards"
                  description="These awards have expired"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import FiscalYearField from "../../gapp-components/components/fields/FiscalYearField.vue";
import MyEarningsTable from "../salesperson/SalespersonEarningsTable.vue";
import ParticipantEarnedWidget from "../widget/ParticipantEarnedWidget.vue";
import ParticipantExpiredWidget from "../widget/ParticipantExpiredWidget.vue";
import ParticipantPendingWidget from "../widget/ParticipantPendingWidget.vue";

export default {
  name: "SalespersonEarnings",
  metaInfo: {
    title: "My Earnings"
  },
  components: {
    FiscalYearField,
    ParticipantEarnedWidget,
    ParticipantPendingWidget,
    MyEarningsTable,
    ParticipantExpiredWidget
  },
  data() {
    return {
      tab: null,
      search: "",
      selectedFiscalYear: {},
      filters: {
        keyword: ""
      }
    };
  },
  methods: {
    onClear() {
      this.search = "";
      this.onSubmit();
    },
    onSubmit() {
      this.filters.createdAfter = this.selectedFiscalYear.fiscalYearStart;
      this.filters.createdBefore = this.selectedFiscalYear.fiscalYearEnd;
      this.filters.keyword = this.search;
    }
  },
  mounted() {
    this.queryTab = this.$route.query.tab;
    if (this.queryTab != null) {
      this.tab = this.queryTab;
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  }
};
</script>
