<template>
  <div>
    <h3>{{ label }}</h3>
    <p>{{ description }}</p>
    <v-data-table
      v-bind="$attrs"
      v-on="$listeners"
      :items="items"
      :headers="headers"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
    >
      <template v-slot:item.createdDate="{ item }">
        {{ item.updatedDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
      </template>
      <template v-slot:item.dealer="{ item }">
        <span v-if="item.sale && item.sale.organization">
          {{
            item.sale.organization.legacyOrganizationKey
              ? item.sale.organization.legacyOrganizationKey
              : item.sale.organization.organizationKey
          }}
          -
          {{ item.sale.organization.name }}
        </span>
      </template>
      <template v-slot:item.program="{ item }">
        <span v-if="item.promotion && item.promotion.promotionType">
          {{ item.promotion.promotionType.promotionTypeKey }} -
          {{ item.promotion.promotionType.name }}
        </span>
      </template>
      <template v-slot:item.model="{ item }">
        <span v-if="item.saleProduct && item.saleProduct.product">
          {{ item.saleProduct.product.productKey }}
        </span>
      </template>
      <template v-slot:item.vin="{ item }">
        <span v-if="item.saleProduct">
          {{ item.saleProduct.serialNumber }}
        </span>
      </template>
      <template v-slot:item.retailPurchaseDate="{ item }">
        <span v-if="item.sale && item.sale.customFieldValue8">
          {{ item.sale.customFieldValue8 | formatDateClient("MM/DD/YYYY", selectedClient) }}
        </span>
      </template>
      <template v-slot:item.payoutAmount="{ item }">
        {{ item.calculatedPayoutAmount | toNumber(2, selectedProgram) }}
        {{ item.calculatedPayoutAmountCurrency ? item.calculatedPayoutAmountCurrency : "" }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SalespersonEarningsTable",
  props: {
    search: { type: String, default: "" },
    pending: { type: Boolean, default: null },
    expired: { type: Boolean, default: null },
    label: String,
    description: String
  },
  components: {},
  data() {
    return {
      tab: null,
      loading: true,
      items: [],
      nameFilter: "",
      expanded: [],
      headers: [
        {
          value: "createdDate",
          text: "Earned Date",
          sortable: true
        },
        {
          value: "dealer",
          text: "Dealer",
          sortable: false
        },
        {
          value: "program",
          text: "Program",
          sortable: false
        },
        {
          value: "model",
          text: "Model",
          sortable: false
        },
        {
          value: "vin",
          text: "VIN",
          sortable: false
        },
        {
          value: "retailPurchaseDate",
          text: "Retail Purchase Date",
          sortable: false
        },
        {
          value: "awardVehicleName",
          text: "Award Type",
          sortable: true
        },
        {
          value: "payoutAmount",
          text: "Value",
          sortable: true,
          align: "right"
        }
      ],
      total: 0,
      options: {
        sortBy: ["createdDate"],
        sortDesc: [true],
        itemsPerPage: 10
      }
    };
  },
  methods: {
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 0;
      this.getData();
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {
        participant: { id: this.selectedParticipant.id }
      };

      if (this.expired != null) {
        filters.isEffective = this.expired ? "Inactive" : "Active";
      }

      if (this.pending != null) {
        filters.completelyPaid = this.pending ? false : true;
      }

      if (this.search != null && this.search.trim().length > 0) {
        filters.keyword = this.search.trim();
      }

      if (this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd) {
        filters.createdAfter = this.selectedFiscalYear.fiscalYearStart;
        filters.createdBefore = this.selectedFiscalYear.fiscalYearEnd;
      }

      return this.$api
        .post(
          "/api/payouts/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "Reports", exact: true },
      { text: "My Earnings" }
    ]);
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    },
    search() {
      this.getData();
    },
    selectedFiscalYear() {
      this.getData();
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram", "selectedFiscalYear"])
  }
};
</script>
